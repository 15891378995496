<!-- 经费申请 -->
<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <div class="search_center">
              单据编号：
              <el-input
                placeholder="请输入单据单编号"
                v-model="parameter.expenseNumber"
                class="ipt_width expense__width"
                clearable
              >
              </el-input>
            </div>
            <div class="search_center">
              项目名称：
              <el-input
                placeholder="请输入项目名称"
                v-model="parameter.projectContent"
                class="ipt_width project_width"
                clearable
              >
              </el-input>
            </div>
            <div class="search_center">
              冲销状态：
              <Dictionary
                v-model="parameter.counteractType"
                code="WRITE_OFF_TYPE"
                placeholder="请选择冲销状态"
              />
            </div>
            <div class="search_center">
              申请日期：
              <el-date-picker
                class="date_width"
                v-model="createdDate"
                :pickerOptions="pickerOptions"
                type="daterange"
                range-separator="至"
                start-placeholder="申请日期（始）"
                end-placeholder="申请日期（末）"
              >
              </el-date-picker>
            </div>
            <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
              >搜索</el-button
            >
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          </div>
        </div>
        <div class="fr">
          <el-button type="primary" @click="dialogVisible = true">预支流程</el-button>
          <el-button type="warning" icon="el-icon-plus" @click="handleAdd">新增</el-button>
          <el-button type="success" style="padding: 0"
            ><a
              style="display: block; height: 40px; width: 100px; line-height: 40px"
              target="_blank"
              href="https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/经费预支单据操作手册.pdf"
              >操作文档</a
            ></el-button
          >
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-tabs v-model="parameter.applyType" class="fullScreenMainHeader">
            <el-tab-pane name="UNDETERMINED">
              <span slot="label">未提交 ({{ statistics.undetermind }})</span>
            </el-tab-pane>
            <el-tab-pane name="APPROVAL">
              <span slot="label">审批中 ({{ statistics.approval }})</span>
            </el-tab-pane>
            <el-tab-pane name="APPROVAL_REJECT">
              <span slot="label">不通过 ({{ statistics.approvalReject }})</span>
            </el-tab-pane>
            <el-tab-pane name="NOT_PAYED">
              <span slot="label">已审核 ({{ statistics.notPayed }})</span>
            </el-tab-pane>
            <el-tab-pane name="APPROVAL_PASS">
              <span slot="label">已支付 ({{ statistics.approvalPass }})</span>
            </el-tab-pane>
          </el-tabs>
          <el-table
            v-loading="loading"
            ref="multipleTable"
            tooltip-effect="dark"
            height="string"
            border
            :key="Math.random()"
            :data="tableData"
          >
            <el-table-column type="index" label="序号" align="center" width="50"></el-table-column>
            <el-table-column
              prop="expenseNumber"
              label="单据编号"
              align="center"
              min-width="170"
            ></el-table-column>
            <el-table-column
              prop="projectContent"
              :show-overflow-tooltip="false"
              min-width="320"
              sortable
              label="项目名称"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="companyType"
              :show-overflow-tooltip="false"
              min-width="130"
              sortable
              label="费用归属单位"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.companyType | dict(companyTypeList) }}
              </template>
            </el-table-column>

            <el-table-column prop="cost" sortable min-width="110" label="预支金额" align="center">
              <template slot-scope="scope">
                {{ scope.row.cost | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              prop="taskDate"
              sortable
              min-width="110"
              label="申请日期"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.taskDate | dateFormat }}
              </template>
            </el-table-column>
            <!-- <el-table-column prop="createdDate" min-width="110" label="填报时间" align="center">
              <template slot-scope="scope">
                {{ scope.row.createdDate | dateFormat }}
              </template>
            </el-table-column> -->
            <el-table-column
              prop="predictCounteractDate"
              min-width="170"
              sortable
              label="预计冲销/归还时间"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.predictCounteractDate | dateFormat }}
              </template>
            </el-table-column>
            <template v-if="parameter.applyType == 'APPROVAL_PASS'">
              <el-table-column
                prop="counteractType"
                min-width="110"
                label="冲销状态"
                sortable
                align="center"
              >
                <template slot-scope="scope">
                  {{ scope.row.counteractType | dict(counteractType) }}
                </template>
              </el-table-column>
              <el-table-column
                prop="sumCounteractCost"
                sortable
                min-width="130"
                label="实际冲销金额"
                align="center"
              >
                <template slot-scope="scope">
                  {{ scope.row.sumCounteractCost | applyAmount }}
                </template>
              </el-table-column>
              <el-table-column
                prop="counteractDeadline"
                min-width="170"
                sortable
                label="截止冲销/归还限期"
                align="center"
              >
                <template slot-scope="scope">
                  {{ scope.row.counteractDeadline | dateFormat }}
                </template>
              </el-table-column>
              <el-table-column
                prop="actualCounteractDate"
                min-width="170"
                sortable
                label="实际冲销/归还时间"
                align="center"
              >
                <template slot-scope="scope">
                  {{ scope.row.actualCounteractDate | dateFormat }}
                </template>
              </el-table-column>
            </template>
            <el-table-column
              prop="statusName"
              sortable
              min-width="150"
              label="流程进度"
              align="center"
            >
            </el-table-column>
            <el-table-column label="操作" align="center" fixed="right" width="110">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  v-if="parameter.applyType != 'UNDETERMINED'"
                  size="small"
                  class="text_Details_Bgc"
                  @click.stop="handleDetails(scope.row)"
                >
                  详情
                </el-button>
                <el-button
                  v-if="
                    parameter.applyType == 'UNDETERMINED' ||
                    parameter.applyType == 'APPROVAL_REJECT'
                  "
                  type="text"
                  class="text_Edit_Bgc"
                  size="small"
                  @click.stop="handleEdit(scope.row)"
                >
                  编辑
                </el-button>
                <el-button
                  type="text"
                  class="text_Remove_Bgc"
                  size="small"
                  v-if="parameter.applyType == 'UNDETERMINED'"
                  @click.stop="removeAdvance(scope.row.id)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>

    <Edit v-if="editShow" :isShow.sync="editShow" :options="options"></Edit>

    <el-dialog title="经费预支流程图" :visible.sync="dialogVisible" width="75%">
      <img src="@/assets/经费预支流程.png" alt="经费预支流程" width="100%" />
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Edit: () => import('../dlg/Edit.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
  },
  provide() {
    return {
      re: this.closeSubgroup,
    }
  },
  data() {
    return {
      loading: false, // 表格 加载
      // 查询条件
      parameter: {
        expenseNumber: null,
        counteractType: null,
        projectContent: null,
        applyType: 'UNDETERMINED',
        pageNow: 1,
        pageSize: 50,
        total: 0, // 表格 -- 数据 总数量
      },
      pageSize: 0,
      tableData: [], // 表格数据
      editShow: false, // "编辑 -- 组件" 隐藏 或 显示
      companyTypeList: [],
      createdDate: [],
      statistics: {
        undetermind: 0,
        approval: 0,
        approvalReject: 0,
        approvalPass: 0,
        notPayed: 0,
      },
      dialogVisible: false,
      pickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.createdDate, 0, v.minDate.getTime())
            this.$set(this.createdDate, 1, '')
          }
        },
      },
      options: {},
      counteractType: [], //冲销转换类型
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  watch: {
    'parameter.applyType': {
      immediate: true, //初始化立即执行
      // deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        this.getTableData()
      },
    },
    createdDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.createdDate = []
        }
      },
    },
  },
  filters: {
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return n
      }
    },
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getType()
  },
  methods: {
    // 清空
    handleReset() {
      this.parameter.startDate = null
      this.parameter.endDate = null
      this.parameter.expenseNumber = null
      this.parameter.counteractType = null
      this.parameter.projectContent = null
      this.parameter.pageNow = 1
      this.createdDate = []
      this.getTableData() /** 加载  数据 **/
    },
    // 删除
    removeAdvance(id) {
      this.$confirm('是否要删除该经费预支申请单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$api.funds
            .deleteExpense({ id })
            .then(res => {
              this.$message({
                message: '删除成功!',
                type: 'success',
              })
              this.getTableData()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },

    /** 加载 "经费预支申请" 数据 **/
    getTableData() {
      this.loading = true
      if (this.createdDate && this.createdDate != []) {
        this.parameter.startDate = new Date(this.createdDate[0]).getTime()
        this.parameter.endDate = new Date(this.createdDate[1]).getTime()
      } else if (this.createdDate == null || this.createdDate == []) {
        this.parameter.startDate = null
        this.parameter.endDate = null
      }
      this.$api.funds
        .getApplyForList(this.parameter)
        .then(res => {
          this.loading = false
          this.tableData = []
          this.parameter.total = 0
          this.tableData = res?.data?.records
          this.parameter.total = res?.data?.total

          this.$api.funds.getApplyStatistics(this.parameter).then(res => {
            if (res.data) {
              res.data.forEach(v => {
                if (v.applyType == 'UNDETERMINED') {
                  this.statistics.undetermind = v.sumQuantity
                } else if (v.applyType == 'APPROVAL') {
                  this.statistics.approval = v.sumQuantity
                } else if (v.applyType == 'APPROVAL_REJECT') {
                  this.statistics.approvalReject = v.sumQuantity
                } else if (v.applyType == 'APPROVAL_PASS') {
                  this.statistics.approvalPass = v.sumQuantity
                } else if (v.applyType == 'NOT_PAYED') {
                  this.statistics.notPayed = v.sumQuantity
                }
              })
            }
          })
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    /** 点击 "新增" 按钮 **/
    handleAdd() {
      this.options = {}
      this.options.isApply = true
      this.options.isExport = false
      this.options.isReject = false
      this.options.isPrint = false
      this.options.title = '新增经费预支单'
      this.editShow = true
    },
    /**
     * 点击 "表格 -- 详情" 操作
     * @param item      当前数据
     */
    handleDetails(item) {
      this.options = item
      this.options.disabled = true
      this.options.isReject = true
      if (this.parameter.applyType == 'NOT_PAYED' || this.parameter.applyType == 'APPROVAL_PASS') {
        this.options.isExport = true
        this.options.isPrint = true
        this.options.isReject = false
      }
      this.options.title = '查看预支单详情'
      this.editShow = true
    },

    /**
     * 点击 "表格 -- 编辑" 操作
     * @param item      当前数据
     */
    handleEdit(item) {
      this.options = item
      this.options.isApply = true
      this.options.isExport = false
      this.options.isReject = false
      this.options.isPrint = false
      this.options.title = '编辑经费预支单'
      this.editShow = true
    },

    // 分页
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getTableData()
    },
    // 条数
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getTableData()
    },
    //全局分页保存
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }

      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getTableData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    /** 关闭 "编辑" 组件 **/
    closeSubgroup() {
      this.getTableData()
      this.editShow = false
    },
    getType() {
      this.$api.dict
        .listSysDictData('COMPANY_TYPE')
        .then(res => {
          this.companyTypeList = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('WRITE_OFF_TYPE')
        .then(res => {
          this.counteractType = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style scoped lang="scss">
.mainList .mainList_content .mainList_content_sub .el-table {
  height: calc(100% - 93px);
}

.status {
  margin-bottom: 15px;
}
</style>
